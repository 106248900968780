import React, {useCallback, useEffect, useState} from 'react';
import {useOutlet} from 'reconnect.js';
import * as Generic from '../../Generic';
import styled from 'styled-components';
import {message} from 'antd';
import moment from 'moment';

function AdminInvoice(props) {
  const [actions] = useOutlet('actions');
  const yearOfROC = (
    Number.parseInt(moment(new Date()).format('YYYY'), 10) - 1911
  ).toLocaleString();
  const month = moment(new Date()).format('MM');
  const monthMappingTable = {
    '01': '02',
    '02': '02',
    '03': '04',
    '04': '04',
    '05': '06',
    '06': '06',
    '07': '08',
    '08': '08',
    '09': '10',
    10: '10',
    11: '12',
    12: '12',
  };
  const [remainInvoiceCount, setRemainInvoiceCount] = useState([]);

  const fetchInvoices = useCallback(
    async (values) => {
      try {
        actions.setLoading(true);
        const resp = await actions.fetchInvoices(values);
        const result = resp.reduce((acc, booklet) => {
          acc += booklet.end - booklet.start + 1;
          return acc;
        }, 0);
        setRemainInvoiceCount(result);
      } catch (e) {
        console.log('debug', e);
      } finally {
        actions.setLoading(false);
      }
    },
    [actions],
  );

  useEffect(() => {
    fetchInvoices({
      period: `${yearOfROC}${monthMappingTable[month]}`,
    });
  }, [actions, fetchInvoices, month, monthMappingTable, yearOfROC]);

  // const searchForm = {
  //   schema: {
  //     title: '',
  //     type: 'object',
  //     required: ['year', 'month'],
  //     properties: {
  //       year: {
  //         type: 'string',
  //         title: '年份',
  //         default: yearOfROC,
  //       },
  //       month: {
  //         type: 'string',
  //         title: '期數',
  //         default: month,
  //         enum: ['02', '04', '06', '08', '10', '12'],
  //         enumNames: ['2月份', '4月份', '6月份', '8月份', '10月份', '12月份'],
  //       },
  //     },
  //   },
  //   uiSchema: {},
  // };
  //
  const bookForm = {
    schema: {
      title: '',
      type: 'object',
      required: ['year', 'month', 'booklet'],
      properties: {
        year: {
          type: 'string',
          title: '年份',
          default: yearOfROC,
        },
        month: {
          type: 'string',
          title: '期數',
          default: month,
          enum: ['02', '04', '06', '08', '10', '12'],
          enumNames: ['2月份', '4月份', '6月份', '8月份', '10月份', '12月份'],
        },
        booklet: {
          type: 'string',
          title: '卷數',
          default: '1',
          enum: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          enumNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        },
      },
    },
    uiSchema: {},
  };

  return (
    <Wrapper>
      {/*<Generic.Form*/}
      {/*  schema={searchForm.schema}*/}
      {/*  uiSchema={searchForm.uiSchema}*/}
      {/*  onSubmit={async (formData) => {*/}
      {/*    try {*/}
      {/*      actions.setLoading(true);*/}
      {/*      const {year, month} = formData;*/}
      {/*      await fetchInvoices({*/}
      {/*        period: `${year}${month}`,*/}
      {/*      });*/}
      {/*    } catch (e) {*/}
      {/*      message.error('失敗');*/}
      {/*    } finally {*/}
      {/*      actions.setLoading(false);*/}
      {/*    }*/}
      {/*  }}*/}
      {/*/>*/}
      <h1>
        {yearOfROC} 年{monthMappingTable[month]} 月
      </h1>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h2>當期剩餘可以使用發票數量：</h2>
        <h1>{remainInvoiceCount}</h1>
      </div>
      <div style={{margin: '50px 0'}} />
      <h1>申請發票</h1>
      <Generic.Form
        schema={bookForm.schema}
        uiSchema={bookForm.uiSchema}
        onSubmit={async (formData) => {
          try {
            actions.setLoading(true);
            const {booklet, year, month} = formData;
            const payload = {
              period: `${year}${month}`,
              booklet: booklet,
            };
            const resp = await actions.bookInvoices(payload);
            try {
              await fetchInvoices({
                period: `${yearOfROC}${monthMappingTable[month]}`,
              });
            } catch (e) {
              message.success('資料刷新失敗，請重新整理頁面');
            }
            message.success(resp.msg);
          } catch (e) {
            const {response} = e;
            message.error(response.detail);
          } finally {
            actions.setLoading(false);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default AdminInvoice;
